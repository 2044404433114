body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1
{
  font-size: 55px;
  color: #374657;
  font-weight: 700;
}

h2
{
  font-size: 36px;
  color: #374657;
  font-weight: 700;
}

h3
{
  font-size: 22px;
  color: #374657;
  font-weight: 700;
}

h4
{
  font-size: 16px;
  color: #374657;
  font-weight: 700;
}

p
{
  font-size: 16px;
  color: #747679;
  font-weight: 400;
}

a
{
  color: inherit;
}

header *, footer *{
  text-decoration: none;
}